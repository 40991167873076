/* eslint-disable jsx-a11y/img-redundant-alt */

import React from 'react';
import _ from 'lodash';
import jsonp from 'jsonp';
import querystring from 'querystring';
import image from  '../../assets/images/cl.ico';
import { reqSearchInfo } from '../../api';
import { ping } from './ping';
import { config, selectConfig } from './config.jsx'

window.baidu={};

function initlocalData () {
  let localData = localStorage.getItem('zdylink') || [];
  if (typeof localData === 'string') {
    localData = JSON.parse(localData);
  }

  if (localData && localData.length) {
    localData.forEach(localItem => {
      if (localItem.indexMap.length) {
        const { name, url, indexMap } = localItem;
        const [itemIndex, childIndex] = indexMap;
        
        for(let i = 0; i < config.length; i++) {
          if (i === itemIndex) {
            for(let j = 0; j < config[i].content.length; j++) {
              if (j === childIndex) {
                config[i].content[j].name= name;
                config[i].content[j].url= url;
                return
              }
            }
            return
          }
        }
      }
    })
  }
}

function init () {
  var t = document.getElementsByTagName("html")[0].getBoundingClientRect().width
    , e = document.getElementsByTagName("html")[0];
  e.style.fontSize = t > 640 ? "40px" : t / 16 + "px"
}

function initCurrent() {
  let current = localStorage.getItem('current') || {};
  if (typeof current === 'string') {
    current = JSON.parse(current);
  }
  if (!current.name) {
    return selectConfig[0]
  }
  return current;
}

initlocalData();
class Home1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      modalShow: false,
      modalValue: {
        name: '',
        link: ''
      },
      configOption: config,
      indexMap: [],
      current: initCurrent(),
      keylist: [],
      keyword: '',
      isShow: false,
    }
    this.requestFn = _.throttle(this.handleChange, 300);
  }

  baiduKeylist = [];

  componentDidMount() {
    init();
    this.initEvent();
  }

  initEvent = () => {
    window.addEventListener('resize', () => {
      init();
    })
  }

  handleModalClose = () => {
    this.setState({ show: false, modalShow: false, modalValue: { name: '', link: '' }, indexMap: [] })
  }

  handleConfirm = () => {
    const { modalValue, configOption, indexMap } = this.state;

    const { name, link } = modalValue;
    const [itemIndex, childIndex] = indexMap;

    const newConfigOption = configOption.map((item, itemI) => {
      if (itemI === itemIndex) {
        item.content.forEach((ci, cindex) => {
          if (cindex === childIndex) {
            ci.name = name
            ci.url = link;
          }
        })
      }
      return item
    })
    let localData = localStorage.getItem('zdylink') || [];
    if (typeof localData === 'string') {
      localData = JSON.parse(localData);
      localData = localData.filter(i => i.indexMap.join(',') !== indexMap.join(','));
    }
    localData.push({ name, url: link, indexMap });
    localStorage.setItem('zdylink', JSON.stringify(localData));
    this.setState({ configOption: newConfigOption}, () => this.handleModalClose())
  }

  handleChange = (val) => {
    const that = this;
    if (val !== '' && val !== null && val !== undefined) {
      const q = querystring.encode({ wd: String(val) });
      jsonp(`https://sp0.baidu.com/5a1Fazu8AA54nxGko9WTAnF6hhy/su?${q}`, 
        {  param:'call_back', timeout: 10000, name: 'jsonpCallback' },
        (err, val) => {}
      )
    }

    window.baidu.sug=function(json){
      const { s = [] } = JSON.parse(JSON.stringify(json));
      that.baiduKeylist = s.map(i => ({ name: i, url: i }));
      that.setState({ keyword: val }, () => that.requestSearch());
    }
  }

  requestSearch = () => {
    const { keyword } = this.state;
    // 当有值时进行查询
    if (keyword !== '' && keyword !== null && keyword !== undefined) {
      reqSearchInfo({ keyword  }).then(res => {
        const { status, data } = res;
        if (status === 200  && data.code === 1) {
          // const newList = [...data.data, ...this.baiduKeylist];
          if (data.data.length) {
            const newList = data.data;
            const len = newList.length;
            this.setState({ keylist: newList.splice(0, len > 6 ? 6 : len) }, this.checklatency)
          } else {
            this.setState({ keylist: [], isShow: false }, () => this.handleSearch());
          }
        } 
      }).catch(err => {
        this.setState({ keylist: [], isShow: false }, () => this.handleSearch());
      })
    }
  }

  checklatency = () => {
    const { keylist } = this.state;
    let that = this;

    for (let i = 0; i < keylist.length; i++) {
      ping(keylist[i].url).then(function(delta) {
				var ping = delta/8;
				ping = Math.floor(ping);
        // keylist[i].delta = `${ping}ms`;
        // console.log(1111, i)
        that.setState({ isShow: true });
        document.querySelector(`.latency${i}`).innerHTML = `${ping}ms`;
				// $( "keylist.latency" ).eq(index).html (ping + "ms");
			}).catch(function(error) {
        // keylist[i].delta = '站点的连接超时';
        that.setState({ isShow: true });
        document.querySelector(`.latency${i}`).innerHTML = '站点的连接超时';
				// $( ".latency" ).eq(index).html ("站点的连接超时");
			});
    }
  }

  handleSearch = (item) => {
    const { keyword, keylist, current } = this.state;
    const goToDefaultPage = (key) => {
      const { sign, link } = current;
      if (link) {
        const url = link + '?' +  querystring.encode({ [sign]: key });
        window.open(url);
      }
    }

    if (item) {
      if (item.name === item.url) {
        goToDefaultPage(item.name)
      } else {
        window.open(item.url);
      }
    } else {
      if (keylist.length) {
        const content = keylist[0];
        if (content.name === content.url) {
          goToDefaultPage(content.name)
        } else {
          window.open(content.url);
        }
      // 反之走默认搜索
      } else {
        goToDefaultPage(keyword)
      }
    }
  }

  render() {
    const { show, modalShow, modalValue, configOption, current, keylist, keyword, isShow } = this.state;
    return (
      <>
        <div className="zdy_button" onClick={() => this.setState({ show: !show })}>
          <img src={image} alt="image"/>
        </div>

        <div className="zdy" style={{ display: modalShow ? 'block' : 'none' }}>
          <div className="zdy_content">
            <div className="zdy_top">
              <span className="zdy_edit"></span>自定义链接
              <span className="zdy_close" onClick={this.handleModalClose}></span>
            </div>
            <div className="zdy_mi">
              <div className="z_m_i">
                <span className="z_i_img_1"></span>
                <input 
                  type="text" 
                  defaultValue={modalValue.name} 
                  className="zdy_input zdy_name" 
                  placeholder="微博" 
                  onChange={(e) => this.setState({ modalValue: { ...modalValue, name: e.target.value }})}
                />
              </div>
              <div className="z_m_i">
                <span className="z_i_img_2"></span>
                <input 
                  type="text" 
                  defaultValue={modalValue.link} 
                  className="zdy_input zdy_link" 
                  placeholder="http://www.weibo.com/" 
                  onChange={(e) => this.setState({ modalValue: { ...modalValue, link: e.target.value }})}
                />
              </div>
              <div>
                <font size="2" color="gray">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请注意：数据是临时存储在本地电脑上，数据丢失无法找回</font>
              </div>
            </div>
            <div className="zdy_foot">
              <div className="zdy_false zdy_b" onClick={this.handleModalClose}>取消</div>
              <div className="zdy_true zdy_b" onClick={this.handleConfirm}>确定</div>
            </div>
          </div>
        </div>

        <div className="baidu baidu-2">
          <div className="form">
            <div className="Select-box-2" id="baidu">
              <ul>
                <li className="this_s" key={current.name}>{current.name}</li>
                {
                  selectConfig.map((item, index) => {
                    if (current && current.name && current.name === item.name) {
                      return null;
                    }
                    return <li className="this_s" onClick={() => {
                      localStorage.setItem('current', JSON.stringify(item));
                      this.setState({ current: item })
                    }} key={item.name}>{item.name}</li>;
                  })
                }
              </ul>
            </div>
            <div className="in5">
              <input 
                defaultValue={keyword} 
                name="wd" 
                id="kw-2"
                maxLength="100" 
                autoComplete="off" 
                type="text" 
                onKeyUp={(e) => {
                  if (e && e.keyCode === 13) {
                    this.requestSearch();
                  }
                }}
                onChange={e => this.setState({ keyword: e.target.value })}
                // onChange={(e) => this.requestFn(e.target.value)}
              />
              <div 
                className="qingkong"
                id="qingkong" 
                title="清空" 
                style={{ display: keyword ? 'block' : 'none' }}
                onClick={() => {
                  document.querySelector('#kw-2').value = '';
                  this.setState({ keyword: '' })
                }}
              >x</div>
            </div>
            <input value="搜 索" id="su-2" type="submit" onClick={() => this.requestSearch()} />
          </div>
          <ul className="keylist">
            {
              // keylist.map(item => (<li key={item.name} onClick={() => {
              //   this.handleSearch(item);
              // }}>{item.name}</li>))
            }
          </ul>
        </div>

        <div className="body">
          <div className="content">
            <div className="table-box" style={{ display: isShow ? 'block' : 'none' }}>
              <h5><span className="light-blue-text">1</span> 线路选择 <span className="right" onClick={this.checklatency}>重新测速</span></h5>
              <div className="table-wrapper">

              <table>
                <thead>
                  <tr>
                    <th>登录端口</th>
                    <th>测速网址（点击网址进入)</th>
                    <th>
                      速度
                      <span className="desktop">（越小表示越快）</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    keylist.map((item, index) => (
                      <tr className="address good" key={item.name}>
                        <td>{item.name}</td>
                        <td>
                          <a href={item.url} target="_blank" rel="noreferrer">
                            <span className="url">{item.url}</span>
                          </a>
                        </td>
                        <td><span className={`latency${index}`}>{item.delta}</span></td>
                      </tr>
                    ))
                  }
                </tbody>

              </table>
              </div>
            </div>
            <div className="jianjie" style={{ display: isShow ? 'none' : 'block' }}>
              {
                configOption.map((item, itemIndex) => (
                  <div className="jj-list" key={item.label}>
                     <div className="jj-list-tit">{item.label}</div>
                     <ul className="jj-list-con _b">
                       {
                         item.content.map((i, index) => (
                          <li key={i.url}>
                            <a href={i.url} className="link-3" target="_blank" rel="nofollow noreferrer">{i.name}</a>
                            <div 
                              className="i_r_edit" 
                              onClick={ () => {
                                this.setState({ modalShow: true, modalValue: { name: i.name, link: i.url }, indexMap: [itemIndex, index]})
                              }} 
                              style={{ display: show ? 'block' : 'none' }} 
                            />
                          </li>
                         ))
                       }
                     </ul>
                  </div>
                ))
              }
              <div className="jj-list zdy_list" style={{ display: 'none' }}>
                <div className="jj-list-tit">自定义</div>
                <ul className="jj-list-con zdy_li">
                </ul>
              </div>
            </div>
          

            <div className="footer1">Copyright © 2021
              <a href="https://www.appts.cn/" style={{ color:'#ffffff' }} target="_blank" rel="nofollow noreferrer">软件探索 </a>
              <a href="https://support.qq.com/products/93263" style={{ color:'#ffffff' }} target="_blank" rel="nofollow noreferrer">反馈</a>
              <a href="https://www.kancloud.cn/appexplore/support/2247036" style={{ color:'#ffffff' }} target="_blank" rel="nofollow noreferrer">支持我们 </a>
            </div>
          </div>

          <div className="footer">Copyright © 2021
            <a href="https://www.appts.cn/" style={{ color:'#ffffff' }} target="_blank" rel="nofollow noreferrer">软件探索 </a>
            <a href="https://support.qq.com/products/93263" style={{ color:'#ffffff' }} target="_blank" rel="nofollow noreferrer">反馈</a>
            <a href="https://www.kancloud.cn/appexplore/support/2247036" style={{ color:'#ffffff' }} target="_blank" rel="nofollow noreferrer">支持我们 </a>
          </div>
        </div>
      </>
    );
  }
}

export default Home1;
