
export const config = [
  {
    label: '学习 · 生活',
    content: [
      {
        url: 'https://www.taobao.com/',
        name: '淘宝',
      },
      {
        url: 'https://www.jd.com/',
        name: '京东',
      },
      {
        url: 'http://123.sogou.com/shwz/yinhang.html',
        name: '银行',
      },
      {
        url: 'https://www.amap.com/',
        name: '地图',
      },
      {
        url: 'https://translate.google.cn/',
        name: '翻译',
      },   
      {
        url: 'https://hao.qq.com/qita/youxiang.html',
        name: '邮箱',
      },
      {
        url: 'https://www.runoob.com/',
        name: '菜鸟教程',
      },
      {
        url: 'https://study.163.com/',
        name: '云课堂',
      },  
      {
        url: 'https://www.icourse163.org/',
        name: 'MOOC',
      }
    ]
  },
  {
    label: '常用 · 社区',
    content: [
      {
        url: 'https://wx.qq.com/',
        name: '微信',
      },
      {
        url: 'https://weibo.com/',
        name: '微博',
      },
      {
        url: 'https://www.zhihu.com/',
        name: '知乎',
      },
      {
        url: 'https://tieba.baidu.com/',
        name: '贴吧',
      },
      {
        url: 'http://www.soomal.com/',
        name: 'Soomal',
      },   
      {
        url: 'https://topbook.cc/overview',
        name: 'Topbook',
      },
      {
        url: 'https://github.com/',
        name: 'GitHub',
      },
      {
        url: 'https://www.douban.com/',
        name: '豆瓣',
      },  
      {
        url: 'https://www.v2ex.com/',
        name: 'V2EX',
      }
    ]
  },
  {
    label: '影音 · 娱乐',
    content: [
      {
        url: 'https://www.iqiyi.com/',
        name: '爱奇艺',
      },
      {
        url: 'https://v.qq.com/',
        name: '腾讯视频',
      },
      {
        url: 'https://www.bilibili.com/',
        name: '哔哩哔哩',
      },
      {
        url: 'https://www.mgtv.com/',
        name: '芒果TV',
      },
      {
        url: 'https://www.youku.com/',
        name: '优酷',
      },   
      {
        url: 'https://music.163.com/',
        name: '音乐',
      },
      {
        url: 'https://www.dikotv.com/',
        name: '顶空影视',
      },
      {
        url: 'https://www.jjmj.tv/',
        name: '九九美剧',
      },  
      {
        url: 'http://www.zzzfun.com/',
        name: 'ZzzFun',
      }
    ]
  },
  {
    label: '发现 · 世界',
    content: [
      {
        url: 'https://news.ifeng.com/',
        name: '凤凰资讯',
      },
      {
        url: 'https://ef.zhiweidata.com/',
        name: '知微事见',
      },
      {
        url: 'https://sspai.com/',
        name: '少数派',
      },
      {
        url: 'https://jikipedia.com/',
        name: '小鸡词典',
      }, 
      {
        url: 'https://jandan.net/',
        name: '煎蛋',
      },
      {
        url: 'https://youquhome.com/',
        name: '有趣网址',
      },
      {
        url: 'https://ac.scmor.com/',
        name: '思谋学术',
      },
      {
        url: 'https://houxu.app/',
        name: '后续',
      },  
      {
        url: 'https://www.soukuzhan.com',
        name: '搜酷站',
      }
    ]
  },
  {
    label: '在线 · 工具',
    content: [
      {
        url: 'https://www.photopea.com/',
        name: '在线PS',
      },
      {
        url: 'https://cowtransfer.com/',
        name: '收发文件',
      },
      {
        url: 'https://cli.im/',
        name: '二维码',
      },
      {
        url: 'https://www.linshiyouxiang.net/',
        name: '临时邮箱',
      }, 
      {
        url: 'https://www.materialtools.com/',
        name: '临时短信',
      },
      {
        url: 'https://convertio.co/zh/',
        name: '格式转换',
      },
      {
        url: 'http://www.html22.com/zh/',
        name: '文档下载',
      },
      {
        url: 'https://weibomiaopai.com/',
        name: '视频下载',
      },  
      {
        url: 'https://www.eggvod.cn/music/',
        name: '音乐下载',
      }
    ]
  },
  {
    label: '搜索 · 资源',
    content: [
      {
        url: 'https://www.cupfox.com/',
        name: '茶杯狐',
      },
      {
        url: 'https://www.dy2018.com/',
        name: '电影天堂',
      },
      {
        url: 'http://vip.yeyulingfeng.com/',
        name: 'VIP解析',
      },
      {
        url: 'https://soft.appts.cn',
        name: '常用软件',
      }, 
      {
        url: 'https://www.coolist.net/',
        name: '酷软清单',
      },
      {
        url: 'https://www.ghxi.com/',
        name: '果核剥壳',
      },
      {
        url: 'https://www.jiumodiary.com/',
        name: '电子书',
      },
      {
        url: 'https://pixabay.com/zh/photos/',
        name: '图片素材',
      },  
      {
        url: 'https://zhengbanxianmian.com/',
        name: '软件限免',
      }
    ]
  },
] 

export const selectConfig = [
  {
    name: '百 度',
    sign: 'wd',
    link: 'https://www.baidu.com/s',
  },
  {
    name: '必 应',
    sign: 'q',
    link: 'https://cn.bing.com/search',
  },
  {
    name: 'Google',
    sign: 'q',
    link: 'https://www.google.com/search',
  },
  {
    name: 'Duck',
    sign: 'q',
    link: 'https://duckduckgo.com/',
  },
  {
    name: '磁 力',
    sign: 'word',
    link: 'http://www.cilimao.cc/search',
  }
]