import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux' // 引入文件使用redux做准备
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import store from './redux/store'
// import Home from './containers/home/index'
import Home1 from './containers/home1/index'
// import Page404 from './containers/404/index'
import './assets/css/index1.less'
import './assets/css/media.less'

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route exact path='/home' component={Home1}></Route>
        {/* <Route exact path='/404' component={Page404}></Route> */}
        <Route path="*" render={() => <Redirect to={'/home'}/>} />
      </Switch>
    </HashRouter>
  </Provider>,
  document.querySelector('#root')
)
